// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-qr-kodovi-za-wifi-js": () => import("./../../../src/pages/blog/qr-kodovi-za-wifi.js" /* webpackChunkName: "component---src-pages-blog-qr-kodovi-za-wifi-js" */),
  "component---src-pages-blog-sto-je-qr-kod-js": () => import("./../../../src/pages/blog/sto-je-qr-kod.js" /* webpackChunkName: "component---src-pages-blog-sto-je-qr-kod-js" */),
  "component---src-pages-hoteli-js": () => import("./../../../src/pages/hoteli.js" /* webpackChunkName: "component---src-pages-hoteli-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paket-basic-js": () => import("./../../../src/pages/paket/basic.js" /* webpackChunkName: "component---src-pages-paket-basic-js" */),
  "component---src-pages-paket-premium-js": () => import("./../../../src/pages/paket/premium.js" /* webpackChunkName: "component---src-pages-paket-premium-js" */),
  "component---src-pages-paket-standard-js": () => import("./../../../src/pages/paket/standard.js" /* webpackChunkName: "component---src-pages-paket-standard-js" */),
  "component---src-pages-politika-privatnosti-js": () => import("./../../../src/pages/politika-privatnosti.js" /* webpackChunkName: "component---src-pages-politika-privatnosti-js" */),
  "component---src-pages-restorani-js": () => import("./../../../src/pages/restorani.js" /* webpackChunkName: "component---src-pages-restorani-js" */),
  "component---src-pages-uvjeti-koristenja-js": () => import("./../../../src/pages/uvjeti-koristenja.js" /* webpackChunkName: "component---src-pages-uvjeti-koristenja-js" */)
}

