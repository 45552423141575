exports.onInitialClientRender = () => {
	const qrCjenik = {
		previousScrollPosition: 0
	};

	window.addEventListener(
		'scroll',
		() => {
			const nav = document.getElementById('nav');
			const currentPosition = window.pageYOffset;
			const scrollingDown = currentPosition > qrCjenik.previousScrollPosition;
			// const scrollingUp = currentPosition < qrCjenik.previousScrollPosition;

			if (nav) {
				if (currentPosition > 35) {
					nav.classList.add('in-scroll');
				} else {
					nav.classList.remove('in-scroll');
				}

				if (currentPosition >= 70) {
					if (scrollingDown) {
						nav.classList.remove('show');
					} else {
						nav.classList.add('show');
					}
				}
			}

			qrCjenik.previousScrollPosition = currentPosition;
		},
		true
	);
};
